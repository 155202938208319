//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import SectionTemplate from './SectionTemplate.vue'
export default {
  extends: SectionTemplate,
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: true,
        arrows: false,
        slidesToShow: 3,
        infinite: false,
        swipe: false,
        variableWidth: false,
        mobileFirst: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              swipe: true,
              variableWidth: false,
              centerMode: true,
              slidesToShow: 1
            }
          }
        ]
      }
    }
  }
}
